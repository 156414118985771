/* DynamicSlide.css */
.dynamicSlide {
    text-align: center;
    position: relative;
    background-color: #E8F0FE;
    color: #333333;
    font-family: "Arial, sans-serif";
    width: 100%;
    min-height: 40vh; /* Default for mobile */
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
/* Media query for desktop screens */
@media (min-width: 768px) {
  .dynamicSlide {
    min-height: 65vh;
  }
}
  